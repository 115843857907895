import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import LayoutContainer from "../components/LayoutContainer"
import LightBackgroundSVG from "../components/LightBackgroundSVG"
import ContactForm from "../components/ContactForm"
import { CONTACT_FORM_ID, CONTACT_US_ROUTE } from "../constants"
import Icon from "../components/Icon"
import { formatPhone } from "../utils"

const PageContainer: React.FC<{ initialContactMessage: string }> = ({
  initialContactMessage,
}) => {
  const data = useStaticQuery(graphql`
    query {
      content {
        contactPhone
        contactEmail
        contactMessage
      }
    }
  `)
  const { contactEmail, contactPhone, contactMessage } = data.content
  return (
    <>
      <section className="relative pb-4 py-2 lg:py-6 bg-white min-h-screen">
        <LayoutContainer>
          <div className="lg:max-w-screen-xl m-auto max-w-screen-md">
            <div className="flex relative z-10 flex-wrap -mx-4">
              <div className="w-full lg:w-6/12 px-4 py-6">
                <h1 className="text-5xl sm:text-6xl font-bold leading-none mb-4">
                  Contact Us
                </h1>
                <p className="mb-4">{contactMessage}</p>
                <div>
                  <div className="mb-2">
                    <a
                      href={`tel:+1${contactPhone}`}
                      className="inline-flex items-center text-teal-700 hover:text-teal-800 transition-colors duration-150"
                    >
                      <div>
                        <Icon name="phone" width={26} />
                      </div>
                      <div className="pl-2">{formatPhone(contactPhone)}</div>
                    </a>
                  </div>
                  <div>
                    <a
                      href={`mailto:${contactEmail}`}
                      className="inline-flex items-center text-teal-700 hover:text-teal-800 transition-colors duration-150"
                    >
                      <div>
                        <Icon name="email" width={24} />
                      </div>
                      <div className="pl-2">{contactEmail}</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="py-4 w-full lg:w-6/12" id={CONTACT_FORM_ID}>
                <div className="py-4 px-4">
                  <ContactForm initialContactMessage={initialContactMessage} />
                </div>
              </div>
            </div>
          </div>
        </LayoutContainer>
        <LightBackgroundSVG />
      </section>
    </>
  )
}

export default ({ location }) => {
  const initialContactMessage =
    location && location.state && location.state.initialContactMessage
      ? location.state.initialContactMessage
      : ""
  return (
    <Layout
      pageTitle="Contact Us"
      pageURL={CONTACT_US_ROUTE}
      pageDescription="We'd love to help answer any questions you might have about our products and services. Feel free to contact us through our web form, via phone or through email."
    >
      <PageContainer initialContactMessage={initialContactMessage} />
    </Layout>
  )
}
